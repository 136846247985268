//TODO: better detect
const qrCode = `<svg version="1.1" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" width="300" height="300">
    <rect shape-rendering="optimizeSpeed" x="0" y="0" width="200" height="200" fill="white" />
    <rect shape-rendering="optimizeSpeed" x="13" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="19" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="43" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="91" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="121" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="175" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="13" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="79" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="91" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="19" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="79" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="121" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="25" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="79" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="31" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="91" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="121" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="37" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="43" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="43" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="43" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="43" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="91" y="43" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="43" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="43" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="43" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="43" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="43" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="43" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="19" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="43" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="121" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="175" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="49" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="55" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="91" y="55" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="55" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="121" y="55" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="55" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="19" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="55" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="139" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="175" y="61" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="19" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="43" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="55" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="139" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="67" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="73" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="73" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="73" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="73" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="79" y="73" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="73" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="73" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="73" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="73" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="121" y="73" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="139" y="73" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="73" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="19" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="43" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="55" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="79" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="121" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="175" y="79" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="55" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="79" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="85" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="43" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="55" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="91" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="121" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="139" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="91" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="19" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="43" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="55" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="91" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="97" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="19" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="43" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="55" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="91" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="139" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="175" y="103" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="109" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="19" y="109" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="109" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="109" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="109" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="109" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="109" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="109" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="109" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="109" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="109" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="109" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="19" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="55" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="121" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="115" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="43" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="55" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="79" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="121" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="55" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="79" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="139" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="175" y="127" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="43" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="79" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="139" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="175" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="133" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="91" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="121" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="175" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="139" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="19" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="43" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="91" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="145" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="151" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="151" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="151" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="91" y="151" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="151" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="151" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="151" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="139" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="157" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="175" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="181" y="163" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="79" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="85" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="145" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="175" y="169" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="175" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="175" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="175" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="79" y="175" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="97" y="175" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="175" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="109" y="175" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="121" y="175" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="133" y="175" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="151" y="175" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="163" y="175" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="175" y="175" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="13" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="19" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="25" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="31" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="37" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="43" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="49" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="61" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="67" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="73" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="79" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="103" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="115" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="127" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="157" y="181" width="6" height="6" fill="black" />
    <rect shape-rendering="optimizeSpeed" x="169" y="181" width="6" height="6" fill="black" /></svg>`

function detectDesktop() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // is mobile..
        console.log('is mobile');
    } else {
        console.log('is desktop');
        //document.body.innerHTML = img;
        const content = {
            h2: 'Badanie wykonywane jest przy pomocy telefonu.',
            qr: `${qrCode}`,
            qrContent: 'Zeskanuj komórką QR kod i przebadaj się na Zespół cieśni nadgarstka ',
            qrAdres: 'Lub przejdz bezpośrednio pod adres: <p><b>CarpalTunnelSyndromeTest.com</b></p>'
        }


        const markup = `
            <div class="container">
                <div class="item header">
                    <p class="grid-content">
                        ${content.h2}
                    </p>
                </div>
                <div class="item content-syndrome">
                    ${content.qrContent}
                </div>
                <div class="icon-container">
                    ${content.qr}
                </div>
                <div class="item cart-icon-content">
                    ${content.qrAdres}
                </div>
            </div>
`;


        document.body.innerHTML = markup;
    }

}

detectDesktop()
